import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import '/src/scss/app.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

;(function() {
  class Site
  {
    constructor() {
      this.initSite();
    }

    initSite() {
     this.header();
     this.mobileHeader();
     this.accordions();
     this.swipers();
     this.backToTop();
    }

    header() {
      const siblings = n => [...n.parentElement.children].filter(c => c !== n);
      const headerMenuLinks = document.querySelectorAll('[data-menu-panel-target]');
      const headerSubmenuPanels = document.querySelectorAll('[data-menu-panel-id]');
      const openHeaderSubmenu = (link) => {
        const targetId = link.dataset.menuPanelTarget;
        const otherLinks = siblings(link);
        const panel = document.querySelector(`[data-menu-panel-id="${targetId}"]`);
        const otherPanels = siblings(panel);

        otherLinks.forEach(link => link.classList.remove('is-open'));
        link.classList.toggle('is-open');

        otherPanels.forEach(panel => panel.classList.remove('is-open'));
        panel.classList.toggle('is-open');

        document.body.classList.toggle('is-menu-open', panel.classList.contains('is-open'));
      };

      const closeAllHeaderSubmenus = () => {
        document.body.classList.remove('is-menu-open');
        headerSubmenuPanels.forEach(panel => panel.classList.remove('is-open'));
      };

      headerMenuLinks.forEach(item => {
        item.addEventListener('click', e => {
          e.preventDefault();
          openHeaderSubmenu(item);
        });
      });

      const headerSubmenuLinks = document.querySelectorAll('.sub-menu__link');

      headerSubmenuLinks.forEach(link => {
        const message = document.querySelector(`.message[data-text-bubble-id="${link.dataset.textBubbleTarget}"]`);
        link.addEventListener('mouseenter', (e => message.classList.add('is-open')));
        link.addEventListener('mouseleave', (e => message.classList.remove('is-open')));
      });
    }

    mobileHeader() {
      const toggleButton = document.querySelector('.hamburger');
      const mobileMenu = document.querySelector('.mobile-menu');

      toggleButton.addEventListener('click', (e => {
        mobileMenu.classList.toggle('is-active');
        toggleButton.classList.toggle('is-active');
        document.body.classList.toggle('is-menu-open');
      }));
    }

    accordions() {
      const accordions = document.querySelectorAll('.accordion');

      accordions.forEach(item => {
        const itemButton = item.querySelector('.accordion__button');
        itemButton.addEventListener('click', e => {
          e.preventDefault();
          item.classList.toggle('is-open');
        });
      });
    }

    swipers() {
      const galleries = document.querySelectorAll('.gallery-swiper');

      galleries.forEach(gallery => {
        new Swiper(gallery, {
          slidesPerView: 1,
          modules: [Navigation, Pagination],
          loop: false,
          pagination: {
            el: '.swiper-pagination',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            768: {
              slidesPerView: 2
            }
          }
        })
      });
    }

    backToTop() {
      const rootElement = document.documentElement;
      const button = document.querySelector('#back-to-top');

      button.addEventListener('click', () => {
        rootElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });

      function checkShowButton() {
        const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
        const isVisible = (rootElement.scrollTop / scrollTotal) > 0.15;
        const remainingSpace = scrollTotal - rootElement.scrollTop;

        button.classList.toggle('is-visible', isVisible);

        if (remainingSpace < 166 && rootElement.clientWidth > 767) {
          button.style.bottom = (180 - remainingSpace) + 'px';
        } else {
          button.style.bottom = null;
        }
      }

      checkShowButton();
      document.addEventListener('scroll', checkShowButton);
    }
  }

  new Site();

}());
